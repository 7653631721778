import React from 'react';
import { Link } from 'react-router-dom';

import Col from 'react-bootstrap/Col';

const AuthorCard = ({ id, name, desc, urlImage, isActive }) => {
  return (
    <Col xs={6} sm={5} md={4} className="mx-5">
      <Link to={`/author/${id}/about`}>
        <div
          className={
            'card border-0 bg-dark position-relative author ' +
            (isActive ? 'active' : '')
          }>
          <div
            className="ratio ratio-1x1 author-img"
            style={{
              backgroundImage: `url(${urlImage})`,
              backgroundSize: 'cover',
            }}></div>
          <div className="ratio ratio-1x1 position-absolute top-0 image-overlay"></div>
          <div className="d-flex flex-column h-100 p-4 text-white text-shadow-1 position-absolute bottom-0 w-100 author-text">
            <h2 className="h4 lh-1 mt-auto">{name}</h2>
            <p className="d-flex px-4 opacity-75">{desc}</p>
          </div>
        </div>
      </Link>
    </Col>
  );
};

export default AuthorCard;
