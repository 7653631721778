import React, { useMemo } from 'react';
import Card from 'react-bootstrap/Card';
import { formatCurrency, getLocaleStrings } from '../functions';

const DetailItem = ({ name, value }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      {value && (
        <>
          <small className="text-secondary">{name}:</small>
          <small className="text-secondary text-end">{value}</small>
        </>
      )}
    </div>
  );
};

const Art = React.memo((props) => {
  const strings = getLocaleStrings();
  const { imageM, name, creator, technique, year, size, price, availability } =
    props;

  const details = useMemo(
    () => [
      { name: strings?.['author'], value: creator?.title?.rendered },
      { name: strings?.['technique'], value: technique?.title?.rendered },
      { name: strings?.['size'], value: size },
      { name: strings?.['year'], value: year },
    ],
    [strings, creator, technique, size, year],
  );

  const detailsList = details.map((i, key) => <DetailItem key={key} {...i} />);

  return (
    <Card className="bg-light border-0 shadow-sm h-100 art">
      <div className="ratio ratio-1x1 p-1">
        <Card.Img
          className="p-3"
          variant="top"
          src={imageM}
          style={{ objectFit: 'contain' }}
        />
      </div>
      <Card.Body>
        <Card.Title>{name}</Card.Title>
        {detailsList}
      </Card.Body>
      <Card.Footer>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ height: 26 }}>
          <big className="text-dark text-bold">
            {price && formatCurrency(price)}
          </big>
          {availability ? (
            <Card.Text className="text-success">
              {strings?.['available']}
            </Card.Text>
          ) : (
            <Card.Text className="text-danger">
              {strings?.['unavailable']}
            </Card.Text>
          )}
        </div>
      </Card.Footer>
    </Card>
  );
});

export default Art;
