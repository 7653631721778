import React, { useEffect, useState } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { authors } from '../const';
import { updateTitle, getLocaleStrings } from '../functions';

import Arts from './Arts';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const AuthorPage = ({ dials, formatArts }) => {
  const strings = getLocaleStrings();
  const [author, setAuthor] = useState({});
  const { params } = useRouteMatch();

  useEffect(() => {
    const authorObject = authors.find((a) => a.id === params.slug);
    setAuthor(authorObject);
    updateTitle(authorObject.name);
  }, [params.slug]);

  const creator = dials.creators.find((c) => c.slug === author.id);

  if (!creator) return null;

  return (
    <Container>
      <Switch>
        <Route path={`/author/${params?.slug}/about`}>
          <About creator={creator} />
        </Route>
        <Route
          path={[
            `/author/${params?.slug}/arts/:page`,
            `/author/${params?.slug}/arts`,
          ]}>
          <Arts
            title={strings['creation']}
            filter={{ creator: creator?.id }}
            {...{ dials, formatArts }}
            author={creator}
          />
        </Route>
      </Switch>
    </Container>
  );
};

const About = (props) => {
  const strings = getLocaleStrings();
  const text_about = strings?.texts_about[props.creator.slug];
  const texts = text_about?.slice(1) || [];

  /*  useEffect(() => {
    updateTitle(props.creator.title.rendered);
  }, []); */

  return (
    <Container className="about-author my-4 pb-2">
      <Row>
        <h2>{text_about[0]}</h2>
      </Row>
      {texts.map((i, key) => (
        <p key={key}>{i}</p>
      ))}
    </Container>
  );
};

export default AuthorPage;
