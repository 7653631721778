import React, { useMemo, useState, useCallback } from 'react';
import { sendEmail, getLocaleStrings } from '../functions';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ReactGA } from '../App';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

const successEmailGA = () => {
  // Send a custom event
  ReactGA.event({
    category: 'contactForm',
    action: 'successEmail',
    label: 'Success email label', // optional
    transport: 'xhr', // optional, beacon/xhr/image
  });
};

const failedEmailGA = () => {
  // Send a custom event
  ReactGA.event({
    category: 'contactForm',
    action: 'failedEmail',
    label: 'Failed email label', // optional
    transport: 'xhr', // optional, beacon/xhr/image
  });
};

const getFormStructure = (preFilledText, strings) => [
  {
    id: 'name',
    name: strings['name'],
    type: 'text',
    placeholder: strings['your-full-name'],
    required: true,
  },
  {
    id: 'email',
    name: strings['email'],
    mutedText: strings['no-share-email'],
    type: 'email',
    placeholder: strings['your-email'],
    required: true,
  },
  {
    id: 'telephone',
    name: strings['mobile'],
    // mutedText: 'Nikdy nebudeme s nikým sdílet vaše telefonní číslo.',
    type: 'tel',
    placeholder: strings['placeholder-mobile'],
    required: true,
  },
  {
    id: 'message',
    name: strings['message-text'],
    textarea: { rows: 3 },
    defaultValue: preFilledText,
    required: true,
  },
];

const Item = ({
  id,
  name,
  type,
  placeholder,
  mutedText,
  textarea,
  defaultValue,
  required,
}) => {
  const strings = getLocaleStrings();
  return (
    <Form.Group className="mb-3" controlId={id}>
      <Form.Label>{name}</Form.Label>
      {textarea ? (
        <Form.Control
          required={required}
          as="textarea"
          name={id}
          rows={textarea.rows}
          placeholder={placeholder || name}
          defaultValue={defaultValue}
        />
      ) : (
        <Form.Control
          required={required}
          type={type}
          name={id}
          placeholder={placeholder || name}
          defaultValue={defaultValue}
        />
      )}
      <Form.Control.Feedback type="invalid">
        {strings['please-fill-field']}
      </Form.Control.Feedback>
      {mutedText && <Form.Text className="text-muted">{mutedText}</Form.Text>}
    </Form.Group>
  );
};

const ContactForm = ({ id = 'myContactForm', preFilledText, callback }) => {
  const strings = getLocaleStrings();
  const [validated, setValidated] = useState(false);
  const [sending, setSending] = useState(false);
  const [alert, setAlert] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      setAlert({
        variant: 'danger',
        content: 'Recaptcha error',
      });
      return;
    }

    return executeRecaptcha('contact');
  }, [executeRecaptcha]);

  const handleResponse = (type, formData, result) => {
    if (type === 'success') {
      successEmailGA();

      const alert = {
        variant: 'success',
        content: `${strings['form-success']}: ${formData?.email}`,
      };

      callback('success', alert);
    } else {
      failedEmailGA();
      let alert;

      switch (result.response.status) {
        case 404: // Not valid token
          alert = {
            variant: 'danger',
            content: strings['fail-success-robot-try-again'],
          };
          break;
        case 400: // Bad params
          console.log(`result.response`, result.response);

          const structure = getFormStructure('', strings);
          const errors = Object.keys(result.response.data.data.params).map(
            (p) =>
              structure.find((s) => s.id === p)?.name ||
              `(${strings['unknown-field']})`,
          );
          alert = {
            variant: 'danger',
            content: `${
              strings['error-field-value-not-correct']
            }: ${errors.join(', ')}`,
          };
          break;
        default:
          alert = {
            variant: 'danger',
            content: strings['unknown-error-sorry'],
          };
          break;
      }

      setAlert(alert);
      //callback('failed', alert);
    }
  };

  const handleSubmit = async (event) => {
    // Prevent event default actions
    event.preventDefault();
    // Show validation
    setValidated(true);

    // Get form element
    const form = event.currentTarget;

    // Form validation
    if (form.checkValidity() === false) {
      return;
    }
    // Show loader and disable send button
    setSending(true);

    // Get reCaptcha token
    const token = await handleReCaptchaVerify();

    // Get form data
    const formData = new FormData(form);
    const formDataObj = { token };
    for (var pair of formData.entries()) {
      formDataObj[pair[0]] = pair[1];
    }

    // Send email
    const result = await sendEmail(formDataObj);

    // Stop loader and disable button
    setSending(false);

    if (result.status === 200) {
      // Reset form
      form.reset();
      setValidated(false);
      // Send callback
      return handleResponse('success', formDataObj);
    } else {
      // Send callback
      return handleResponse('failed', formDataObj, result);
    }
  };

  const formGroups = useMemo(
    () => getFormStructure(preFilledText, strings),
    [strings, preFilledText],
  );

  const list = formGroups.map((i) => <Item key={i.id} {...i} />);

  return (
    <>
      {alert && <Alert variant={alert.variant}>{alert.content}</Alert>}
      <Form id={id} noValidate validated={validated} onSubmit={handleSubmit}>
        {list}
      </Form>
      <p className="text-muted reCaptcha-text">
        {`${strings['re-captcha-text']} `}
        <a href="https://policies.google.com/privacy">
          {strings['re-captcha-link-privacy']}
        </a>
        {` ${strings['and']} `}
        <a href="https://policies.google.com/terms">
          {strings['re-captcha-link-terms']}
        </a>
        .
      </p>
      <Button type="submit" form={id} disabled={sending}>
        {sending ? (
          <>
            <Spinner size="sm" animation="border" role="status" />{' '}
            {strings['sending']}
          </>
        ) : (
          strings['submit-message']
        )}
      </Button>
    </>
  );
};

export default ContactForm;
