import React from 'react';
import Select from 'react-bootstrap/FormSelect';

const LangSwitch = () => {
  const browserLang = window.navigator.language;
  let defaultLang = 'cs';

  if (['cs', 'en', 'de' ].includes(browserLang)) {
    defaultLang = browserLang;
  }

  const locale = localStorage.getItem('locale') || defaultLang;

  const handleChange = (e) => {
    const lang = e.target.value;

    localStorage.setItem('locale', lang);
    window.location.reload();
  };

  return (
    <Select
      onChange={handleChange}
      className="lang-select"
      defaultValue={locale}
      aria-label="Výběr jazyka">
      <option value="cs">CZ</option>
      <option value="en">EN</option>
      <option value="de">DE</option>
    </Select>
  );
};

export default LangSwitch;
