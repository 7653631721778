import React, { Component } from 'react';
import { asyncFetchPosts, updateTitle, getLocaleStrings } from '../functions';
import { Link, withRouter } from 'react-router-dom';
import { optionsPerPage } from '../config.json';

import Art from '../components/Art';
import Filters from '../components/Filters';
import Pagination from '../components/Pagination';
import Loader from '../components/Loader';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

class Arts extends Component {
  constructor(props) {
    super(props);

    const page = props?.match?.params?.page;
    const routeFilter = props.location?.state?.filter || {};
    const passFilter = props?.filter || {};
    const fullFilter = { ...routeFilter, ...passFilter };

    this.state = {
      // data load
      arts: [],
      isLoaded: false,
      error: false,
      detailsLoaded: false,
      // filters
      filterOpen: false,
      filter: fullFilter,
      // pagination
      page: page ? parseInt(page) : 1,
      perPage: 10,
    };

    this.toggleFilterBox = this.toggleFilterBox.bind(this);
    this.getData = this.getData.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.changePage = this.changePage.bind(this);
    this.handleChangePerPage = this.handleChangePerPage.bind(this);
  }

  strings = getLocaleStrings();

  componentDidMount() {
    //console.log('componentDidMount');
    if (this.props.pageTitle) updateTitle(this.props.pageTitle);
    this.checkData();
  }

  componentDidUpdate(prevProps) {
    //console.log('componentDidUpdate');

    const page = parseInt(this.props.match.params.page) || 1;

    if (this.state.page !== page) {
      /**
       * Kvůli komplikacím s vracením předchozího stavu filtrů u tlačítka prohlížeče zpět
       * je implementován následující mechanismus ověřování dat pomocí react-router-dom
       */
      const prevFilter = JSON.stringify(prevProps.location?.state?.filter);
      const actualFilter = JSON.stringify(this.props.location?.state?.filter);

      if (actualFilter !== prevFilter) {
        this.setState({ filter: this.props.location?.filter });
      }

      this.changePage(page, true);
    }
  }

  /**
   * Změní číslo stránky produktů a načte nová data
   * @param {number} page
   */
  changePage(page, forceUpdate = true) {
    //console.log(`changePage`, page);
    if (this.state.page === page) return false;

    const path = this.props.match.path;
    const pathHasPage = path.includes(':page');

    const pathname = pathHasPage
      ? path.replace(':page', page)
      : `${path}/${page}`;

    this.props.history.push({ state: { filter: this.state.filter }, pathname });

    this.setState({ page }, () => forceUpdate && this.getData());
  }

  checkData() {
    //console.log('checkData');
    if (this.props.dials.creators?.length > 0) return this.getData();
    else
      setTimeout(() => {
        this.checkData();
      }, 300);
  }

  /**
   * Zavolá a zpracuje požadavek na nová data
   */
  async getData() {
    //console.log('getData');
    this.setState({ isLoaded: false });

    const params = {
      page: this.state.page,
      per_page: this.state.perPage,
      ...this.state.filter,
    };

    const result = await asyncFetchPosts('arts', params);

    if (result?.data && Array.isArray(result.data)) {
      if (result.data.length === 0) {
        return this.setState({
          isLoaded: true,
          arts: [],
          error: this.strings['no-data'],
        });
      }

      const formattedArts = this.props.formatArts(result.data);
      this.setState({
        arts: formattedArts,
        isLoaded: true,
        totalPages: parseInt(result?.headers?.['x-wp-totalpages']),
        error: null,
      });
    } else {
      this.setState({
        isLoaded: true,
        arts: [],
        error: this.strings['failed-load-data'],
      });
    }

    const element = document.getElementById('authorHeading');
    const isHide = !!document.querySelector('.hideAuthors');
    if (!isHide && element) element.scrollIntoView(true);
  }

  /**
   * Přepínač viditelnosti boxu s filtry produktů
   */
  toggleFilterBox() {
    //console.log('toggleFilterBox');
    this.setState((prev) => {
      return { filterOpen: !prev.filterOpen };
    });
  }

  /**
   * Zpracování změny stavu filtrů
   *
   * @param {string} id
   * @param {string|boolean} value
   */
  handleFilterChange(id, value) {
    //console.log('handleFilterChange');
    this.changePage(1, false);
    const updateData = () => {
      this.props.history.replace(this.props.location.pathname, {
        filter: this.state.filter,
      });
      this.getData();
    };

    if (id === 'reset') {
      // vymažu filtry a obnovím data
      return this.setState({ filter: this.props.filter || null }, updateData);
    }

    this.setState(
      (prev) => ({ filter: { ...prev?.filter, [id]: value } }),
      updateData,
    );
  }

  handleChangePerPage(event) {
    //console.log('handleChangePerPage');
    const { value } = event?.target || {};

    this.changePage(1, false);
    this.setState({ perPage: value }, () => this.getData());
  }

  render() {
    const { isLoaded, arts, page, perPage, totalPages, filterOpen, error } =
      this.state || {};

    const list = arts.map((art) => {
      return (
        <Link
          key={art.id}
          to={{
            pathname: `/art/${art.slug}`,
            state: {
              filter: this.state.filter,
              backToArts: this.props.location,
            },
          }}
          className="link-unstyled">
          <Col>
            <Art {...art} />
          </Col>
        </Link>
      );
    });

    return (
      <Container className="d-flex flex-1 flex-column flex-fill my-4 arts-container">
        <Row className="align-items-center">
          <Col>
            <h2>{this.props.title}</h2>
          </Col>
          <Col className="d-flex justify-content-end">
            {isLoaded && (
              <Button variant="transparent" onClick={this.toggleFilterBox}>
                {`${this.strings['filter']} ` + (filterOpen ? '▲' : '▼')}
              </Button>
            )}
          </Col>
        </Row>

        <Row className="my-2">
          <Filters
            mode={this.state.mode}
            open={filterOpen || this.state.filter}
            dials={this.props.dials}
            onChange={this.handleFilterChange}
            filter={this.state.filter}
          />
        </Row>

        <Row
          xs={1}
          md={2}
          lg={3}
          xl={4}
          xxl={5}
          className="g-4 m-3 flex-fill justify-content-center align-items-center">
          {error && (
            <div className="d-flex flex-fill justify-content-center align-items-center">
              <h3 className="text-danger">
                <strong>{error}</strong>
              </h3>
            </div>
          )}
          {isLoaded ? list : <Loader />}
        </Row>

        <Pagination
          className="mt-auto"
          page={page}
          totalPages={totalPages}
          changePage={this.changePage}
          perPage={perPage}
          onPerPage={this.handleChangePerPage}
          optionsPerPage={optionsPerPage}
        />
      </Container>
    );
  }
}

export default withRouter(Arts);
