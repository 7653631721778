import './App.scss';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { authors } from './const';
import { asyncFetchPosts, updateTitle, getLocaleStrings } from './functions';

// UI Components
import Header from './components/Header';
import Footer from './components/Footer';
import CookieAlert from './components/CookieAlert';

// Pages
import Arts from './pages/Arts';
import ArtPage from './pages/ArtPage';
import AuthorPage from './pages/AuthorPage';
import Contact from './pages/ContactPage';
import GDPR from './pages/GDPR';

function App() {
  const strings = getLocaleStrings();
  const [creators, setCreators] = useState([]);
  const [techniques, setTechniques] = useState([]);

  useEffect(() => {
    const gaID =
      process.env.NODE_ENV === 'development' ? 'G-ZNFDQM6DJW' : 'G-X1805FFDNX';
    ReactGA.initialize(gaID);
    ReactGA.send({ hitType: 'pageview', page: '/' });

    const fetchData = async () => {
      const creatorResult = await asyncFetchPosts('creator');
      const techniqueResult = await asyncFetchPosts('technique');

      if (creatorResult?.status === 200) {
        setCreators(creatorResult?.data);
      }

      if (techniqueResult?.status === 200) {
        setTechniques(techniqueResult?.data);
      }
    };

    fetchData();
  }, []);

  const getCreatorById = useCallback(
    (creatorId) => creators.find((c) => String(c?.id) === String(creatorId)),
    [creators],
  );

  const getTechniqueById = useCallback(
    (techniqueId) =>
      techniques.find((t) => parseInt(t?.id) === parseInt(techniqueId)),
    [techniques],
  );

  /**
   * Funkce pro formátování pole produktů, z číselníků doplňuje objekt: creator a technique
   */
  const formatArts = (arts) => {
    return arts.map((art) => ({
      id: art.id,
      name: art.title?.rendered,
      creator: getCreatorById(art?.acf?.creator),
      technique: getTechniqueById(art?.acf?.technique),
      price: art.acf?.price,
      size: art.acf?.size,
      year: art.acf?.year,
      imageM: art.acf?.image?.sizes?.medium,
      imageL: art.acf?.image?.sizes?.large,
      availability: art.acf?.availability,
      slug: art.slug,
    }));
  };

  const dials = useMemo(
    () => ({ creators, techniques }),
    [creators, techniques],
  );

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeYzBMdAAAAAPEhClA8OGwGltu9XukmEqe3frTn">
      <div className="App d-flex flex-column h-100">
        <BrowserRouter>
          <Header authors={authors} />
          <Switch>
            <Route
              path="/"
              exact
              strict
              render={() => {
                updateTitle(false);
                return <Footer />;
              }}
            />
            <Route path={['/arts/:page', '/arts']}>
              <Arts
                title={strings['all_arts']}
                pageTitle={strings['all_arts']}
                dials={dials}
                formatArts={formatArts}
                artUrl={'/art/:slug'}
              />
              <Footer />
            </Route>
            <Route path="/art/:slug">
              <ArtPage dials={dials} formatArts={formatArts} />
              <Footer />
            </Route>
            <Route path="/author/:slug/:page">
              <AuthorPage dials={dials} formatArts={formatArts} />
              <Footer />
            </Route>
            <Route path="/contact">
              <Contact />
              <Footer />
            </Route>
            <Route path="/gdpr">
              <GDPR />
              <Footer />
            </Route>
          </Switch>
        </BrowserRouter>
        <CookieAlert />
      </div>
    </GoogleReCaptchaProvider>
  );
}

export { ReactGA };
export default App;
