import React, { useState } from 'react';
import { getLocaleStrings } from '../functions';

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

const defaultShow = localStorage.getItem('cookieClick') !== '1';

const CookieAlert = () => {
  const strings = getLocaleStrings();
  const [show, setShow] = useState(defaultShow);

  const handleClick = () => {
    setShow(false);
    localStorage.setItem('cookieClick', 1);
  };

  return (
    <Alert
      variant="info"
      className={`${
        show ? 'd-flex' : 'd-none'
      } position-fixed bottom-0 bg-dark text-white w-100 m-0 border-0`}>
      <Container className="d-flex flex-columns justify-content-between align-items-center">
        {strings['cookies-text']}
        <Button onClick={handleClick}>OK</Button>
      </Container>
    </Alert>
  );
};

export default CookieAlert;
