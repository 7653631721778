import React, { useCallback } from 'react';
import { getLocaleStrings } from '../functions';

import Pagination from 'react-bootstrap/Pagination';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Select from 'react-bootstrap/FormSelect';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const PaginationComponent = ({
  page,
  totalPages,
  changePage,
  className,
  perPage,
  optionsPerPage,
  onPerPage,
}) => {
  const strings = getLocaleStrings();
  const width = window.document.body.offsetWidth;

  const next = useCallback(
    () => changePage(page !== totalPages ? page + 1 : page),
    [changePage, page, totalPages],
  );
  const prev = useCallback(
    () => changePage(page !== 1 ? page - 1 : page),
    [changePage, page],
  );

  const getItems = useCallback(
    (pages, page, totalPages) => {
      const items = [];
      //const firsItem = pages[0];
      const lastItem = pages.slice(-1)[0];

      if (page > 4)
        items.push(
          <Pagination.First key={'first'} onClick={() => changePage(1)} />,
        );

      if (totalPages > 1)
        items.push(
          <Pagination.Prev key={'prev'} disabled={page === 1} onClick={prev} />,
        );

      //if (firsItem !== 1) items.push(<Pagination.Ellipsis />);

      for (let pageItem of pages) {
        items.push(
          <Pagination.Item
            key={pageItem.toString()}
            active={pageItem === page}
            onClick={() => changePage(pageItem)}>
            {pageItem}
          </Pagination.Item>,
        );
      }

      if (lastItem !== totalPages) {
        items.push(<Pagination.Ellipsis key={'rightElips'} />);
      }

      if (totalPages > 1)
        items.push(
          <Pagination.Next
            key={'next'}
            disabled={page === totalPages}
            onClick={next}
          />,
        );

      if (totalPages - page > 3)
        items.push(
          <Pagination.Last
            key={'last'}
            onClick={() => changePage(totalPages)}
          />,
        );

      return items;
    },
    [changePage, next, prev],
  );

  const getPages = (page, totalPages, big = 7) => {
    const pages = [];
    const isBig = totalPages > big;
    const halfBig = big % 2 ? (big - 1) / 2 : big / 2;

    const endPlus = page + (page <= halfBig ? big - page : halfBig);

    const startLinePage = isBig && page > halfBig ? page - halfBig : 1;
    const endLinePage = isBig && endPlus <= totalPages ? endPlus : totalPages;

    for (let i = startLinePage; i <= endLinePage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const paginationSize =
    (width >= 440 && 7) || (width >= 350 && 5) || (width >= 285 && 3) || 1;
  const pages = getPages(page, totalPages, paginationSize);

  const items = getItems(pages, page, totalPages);

  return (
    <Row className={'justify-content-center align-items-center' + className}>
      <Col
        xs={{ span: 10, offset: 1 }}
        sm={{ span: 'auto', offset: 0 }}
        className="d-flex align-items-center p-2">
        <Pagination className={'m-0'}>{items}</Pagination>
      </Col>

      {optionsPerPage && totalPages && (
        <Col xs={{ span: 10, offset: 1 }} sm={5} md={4} lg={3} xl={2} xxl={2}>
          <FloatingLabel label={strings['per-page']}>
            <Select onChange={onPerPage} value={perPage}>
              {optionsPerPage.map((o) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
            </Select>
          </FloatingLabel>
        </Col>
      )}
    </Row>
  );
};

export default PaginationComponent;
