import React, { useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import { updateTitle } from '../functions';

const GDPR = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    updateTitle('GDPR');
  });

  return (
    <Container className="py-4">
      <h2 className="font-weight-bold">
        Informace o zpracování osobních údajů KRYPTONITE INVESTMENTS s.r.o.
      </h2>
      <div className="px-2">
        <p>
          Vážení, společnost KRYPTONITE INVESTMENTS s.r.o., IČ: 07910762, se
          sídlem U Císařských lázní 368/7, 415 01 Teplice, zapsaná v obchodním
          rejstříku u Krajského soudu v Ústí nad Labem, sp. zn. C 43201 (dále
          také jen „naše společnost“ nebo „správce osobních údajů“) si Vás
          dovoluje touto cestou informovat o základních zásadách a principech,
          na základě kterých nakládáme s Vašimi osobními údaji.
        </p>
        <p>
          Osobní údaje získáváme a zpracováváme jen v nezbytném rozsahu proto,
          abychom Vám mohli poskytovat a nabízet kvalitní služby. Při jejich
          zpracování dodržujeme zejména standardy jejich ochrany podle zákona č.
          110/2019 Sb.,o zpracování osobních údajů a nařízení Evropského
          parlamentu a Rady (EU) 2016/679 ze dne 27. 4. 2016, obecné nařízení o
          ochraně osobních údajů (GDPR).
        </p>
        <p>
          Účelem tohoto poučení je poskytnout Vám maximum informací o tom, které
          z Vašich osobních údajů jsou naší společností zpracovávány, z jakých
          zdrojů jsou získávány, k jakým účelům jsou využívány a konečně kterým
          subjektům mohou být osobní údaje poskytnuty. Rovněž také zjistíte, kde
          můžete získat informace o Vašich osobních údajích, zpracovávaných naší
          společností a informace o způsobu jejich zabezpečení.
        </p>
        <ol className="first">
          <li className="font-weight-bold">
            Kategorie osobních údajů, které o Vaší osobě zpracováváme:
            <ol type="a" className="font-weight-normal">
              <li>
                Adresní (kontaktní) a identifikační údaje – údaje k Vaší
                jednoznačné a nezaměnitelné identifikaci a umožňující kontakt s
                Vámi; jméno(a), příjmení, titul, rodné číslo (bylo-li
                přiděleno), pohlaví, datum narození, adresa trvalého pobytu,
                státní příslušnost, u klienta – fyzické osoby podnikatele též
                daňové identifikační číslo a IČ, bylo-li přiděleno; dále
                kontaktní adresa, číslo telefonu – pevná linka, mobil, e-mailová
                adresa a jiné obdobné informace;
              </li>
              <li>
                Skryté technické údaje internetového provozu a informace, které
                webové stránky sbírají pasivně (cookies, IP adresa, User Agent,
                HTTP REFERRER, UTM parametr, geolokační údaje).
              </li>
            </ol>
          </li>
          <li className="font-weight-bold">
            Souhlas a rozsah souhlasu se zpracováním osobních údajů:
            <ol type="a" className="font-weight-normal">
              <li>
                z povahy našeho (i potenciálního) vztahu s Vámi vyplývá, že
                nejste povinen správci osobních údajů poskytnout jakékoliv údaje
                o své osobě ani souhlas s jejich zpracováním a jste oprávněn
                toto odmítnout. Poskytujete-li jakékoliv osobní údaje a/nebo
                souhlas s jejich zpracováním, činíte tak zcela dobrovolně.
                Neposkytnutí osobních údajů ve stanoveném rozsahu však zpravidla
                brání poskytování finančního plánování;
              </li>
              <li>
                poskytnutý souhlas se zpracováním osobních údajů můžete kdykoli
                písemně odvolat na adrese sídla správce osobních údajů, a to v
                celém rozsahu nebo jen částečně;
              </li>
              <li>
                není dána povinnost poskytnout správci osobních údajů osobní
                údaje v rozsahu – elektronická adresa a telefonní číslo – (s
                výjimkou produktů nebo služeb, u kterých z povahy jejich
                konstrukce nutnost jejich poskytnutí plyne);
              </li>
              <li>
                není povinnost poskytnout souhlas se zpracováním osobních údajů
                za účelem nabízení obchodu a služeb nebo souhlas se zasíláním
                tzv. obchodních sdělení podle zvláštního právního předpisu,
                jejich poskytnutím však můžete získat některé výhody (např.
                výhodné nabídky v rámci produktových kampaní, investiční tipy
                atd.);
              </li>
              <li>
                zpracování Vašich osobních údajů bude po uplynutí doby, na níž
                jste souhlas se zpracováním osobních údajů poskytl, nebo po
                zániku tohoto Vašeho souhlasu (při zohlednění rozsahu Vašeho
                event. odvolání tohoto poskytnutého souhlasu) bez zbytečného
                odkladu ukončeno nebo omezeno, pokud správce osobních údajů
                nebude oprávněn či povinen v něm pokračovat z jiného titulu;
              </li>
              <li>
                Vaše osobní údaje budou zpracovány ručně i automatizovaně v
                rozsahu, ve kterém byly poskytnuty nebo budou Vámi doplněny,
                opraveny;
              </li>
              <li>
                {' '}
                Vaše osobní údaje budou zpracovány prostřednictvím zaměstnanců
                správce osobních údajů a dále třetích osob (zejména
                zpracovatelů) na základě smluv o zpracování osobních údajů nebo
                jim obdobných za podmínek Zákona (zpracovateli jsou zejména
                obchodní zástupci činní pro správce osobních údajů); Vaše osobní
                údaje mohou být zpřístupněny jen osobám k tomu oprávněným v
                rámci plnění jejich pracovních úkolů, ať již smluvně převzatých
                nebo plynoucích z právních předpisů, a podléhajícím zákonné či
                smluvně převzaté povinnosti mlčenlivosti;
              </li>
              <li>
                {' '}
                Vaše osobní údaje budou zabezpečeně uchovávány v elektronické
                nebo listinné podobě;
              </li>
              <li>
                {' '}
                máte právo přístupu ke svým osobním údajům a na základě Vaší
                písemné žádosti je Vám správce osobních údajů jednou za
                kalendářní rok povinen bezplatně poskytnout informace o osobních
                údajích o Vás zpracovávaných, jinak kdykoli za přiměřenou úhradu
                nepřevyšující náklady nezbytné k poskytnutí informace;
              </li>
              <li>
                {' '}
                máte právo, domníváte-li se, že správce osobních údajů nebo
                zpracovatel provádí zpracování Vašich osobních údajů v rozporu s
                ochranou Vašeho soukromého a osobního života nebo v rozporu se
                Zákonem:
                <ol type="A">
                  <li>
                    {' '}
                    požádat správce osobních údajů nebo zpracovatele o
                    vysvětlení,
                  </li>
                  <li>
                    {' '}
                    požadovat, aby správce osobních údajů nebo zpracovatel
                    odstranili takto vzniklý stav; správce osobních údajů nebo
                    zpracovatel jsou povinni neprodleně odstranit takto vzniklý
                    stav, je-li Vaše žádost shledána oprávněnou;
                  </li>
                </ol>
              </li>
              <li>
                {' '}
                máte právo obrátit se na Úřad pro ochranu osobních údajů
                (www.uoou.cz) s podnětem nebo stížností, pokud správce osobních
                údajů nebo zpracovatel nevyhoví Vaší žádosti podle předchozího
                odstavce, můžete se na tento úřad obrátit i přímo.
              </li>
            </ol>
          </li>
          <li className="font-weight-bold">
            Účely, ke kterým Vaše osobní údaje zpracováváme:
            <ol type="a" className="font-weight-normal">
              <li>
                {' '}
                účely poskytování poradenské činnosti v oboru nemovitostí
              </li>
              <li>
                {' '}
                účely vnitřní potřeby; zejména ochranu práv a právem chráněných
                zájmů společnosti, sledování kvality služeb a spokojenosti
                klientů a optimalizaci poskytovaných služeb a produktů, obchodní
                a marketingové účely, zejména nabízení obchodů a služeb vč.
                zasílání obchodních sdělení podle zvl. zákona, informování o
                nových produktech a službách a poskytování finančního plánování,
                mají-li klienti o takové služby zájem, resp. neprojeví-li svůj
                nesouhlas.
              </li>
            </ol>
          </li>
          <li className="font-weight-bold">
            Zdroje, ze kterých jsou osobní údaje získávány:
            <ul className="font-weight-normal">
              <li>
                Přímo od Vás – klientů při jednáních a vzájemné komunikaci.
              </li>
            </ul>
          </li>
          <li className="font-weight-bold">
            Doba uchování Vašich osobních údajů:
            <ul className="font-weight-normal">
              <li>
                Vzhledem k účelu (investice do nemovitostí) je Společnost
                oprávněna zpracovávat osobní údaje klienta po dobu třiceti let
                od jejich poskytnutí. Klient může kdykoliv požádat společnost o
                okamžité odstranění svých osobních údajů a společnost je povinna
                do pěti pracovních dnů veškeré údaje ze své evidence odstranit.
                Pokud dojde k úspěšnému zprostředkování produktu ze strany
                společnosti, je společnost oprávněna zpracovávat osobní údaje
                související s tímto produktem po celou dobu existence pohledávek
                z uzavřené smlouvy a dále po dobu 3 let od zániku poslední z
                pohledávek z takové smlouvy.
              </li>
            </ul>
          </li>
          <li className="font-weight-bold">
            Subjekty, kterým můžeme Vaše osobní údaje poskytnout:
            <ul className="font-weight-normal">
              <li>
                K Vašim osobním údajům mají přístup i další subjekty
                (zpracovatelé), kteří jsou našimi smluvními partnery a podílejí
                se naší obchodní činnosti. Tyto subjekty rovněž garantují
                standardy ochrany podle GDPR a mají s námi uzavřené smlouvy o
                ochraně osobních údajů našich klientů.
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </Container>
  );
};

export default GDPR;
