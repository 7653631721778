import React, { useMemo } from 'react';
import { getLocaleStrings } from '../functions';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

import Authors from './Authors';

const getMenu = () => {
  const strings = getLocaleStrings();
  return [
    {
      id: 'intro',
      name: strings['authors'],
      url: '/',
    },
    {
      id: 'arts',
      name: strings['all_arts'],
      url: '/arts',
    },
    {
      id: 'contact',
      name: strings['contact'],
      url: '/contact',
    },
  ];
};

const Header = ({ authors, submenuItems }) => {
  const match = useLocation(window.location.pathname);
  const history = useHistory();
  const hide = match.pathname !== '/';
  const isAuthorPage = match.pathname.includes('/author/');
  const show = isAuthorPage ? 'authorPage' : hide ? 'hideAuthors' : '';

  const menu = useMemo(() => getMenu(), []);

  return (
    <div className={`d-flex flex-column intro ${show}`}>
      <header className="py-1 p-md-3 text-white">
        <Container className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <NavLink to="/" className="text-white me-auto text-decoration-none">
            3x&nbsp;<big>Šavel</big>
          </NavLink>

          <Nav defaultActiveKey="/" onSelect={(url) => history.push(url)}>
            {menu.map((i) => (
              <Nav.Item key={i.url}>
                <Nav.Link eventKey={i.url}>{i.name}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Container>
      </header>

      <Authors authors={authors} show={show} />
    </div>
  );
};

export default Header;
