import vladimirSavel from './assets/images/portret_vladimir_savel.jpg';
import vladimirSavelJr from './assets/images/portret_vladimir_savel_jr.jpg';
import iljaSavel from './assets/images/portret_ilja_savel.jpg';
import { getLocaleStrings } from './functions';

export const texts_about = {
  'vladimir-savel': [
    'Akademický malíř – malíř a grafik',
    'Narozen 14. listopadu 1921 v Kopistech',
    'Zemřel 4. srpna 2006 v Teplicích',
    'Vystudoval Střední odbornou školu keramickou v Praze a Akademii výtvarných umění v Praze (1938-1947), grafickou speciálku u profesorů T. F. Šimona a V. Silovského. Byl členem Unie výtvarných umělců v Teplicích.',
    'Hlavním předmětem jeho uměleckého zájmu byla krajina, měnící svou tvář v současném bouřlivém vývoji severočeského regionu, krajina ovládaná a exploatovaná technikou, ale i krajina intimní pohody, poezie a tichého zamyšlení. V Šavlových dílech není obsažen jen autorův vztah k tématům, ale i všechny jeho zkušenosti malíře nasbírané za život, a také znalost tradice moderní malby. Umělecky významná jsou jeho zátiší. Obrazy květin, ovoce, nahodile seskupených i záměrně aranžovaných předmětů. Zrcadlí se v nich Šavlův umělecký vývoj, protože na zátiších si nejčastěji ověřoval možnosti osobitého přetlumočení skutečnosti a jednotlivé tvůrčí postupy. Vyjadřoval se kresbou, grafickými technikami, malbou a podnětně zasahoval i do tvorby životního prostředí realizacemi v architektuře. Podílel se organizačně na výtvarném životě na severu i v centru republiky. Za svou práci byl mnohokrát oceněn. Zúčastnil se více jak 150 reprezentativních výstav doma i v zahraničí.',
  ],
  'vladimir-savel-jr': [
    'Doc., Mgr. – malíř, grafik a pedagog ',
    'Narozen 8. listopadu 1949 v Ervěnicích',
    'Vystudoval obor monumentální malby na Střední uměleckoprůmyslové škole v Uherském Hradišti. Dále studoval na katedře výtvarné výchovy pedagogické fakulty v Ústí nad Labem, na katedře výtvarné výchovy pedagogické fakulty Univerzity Karlovy v Praze a na katedře dějin a teorie výtvarného umění filozofické fakulty UK tamtéž. V roce 1989 se habilitoval v oboru malířství na pedagogické fakultě Univerzity Palackého v Olomouci. Je docentem katedry výchov uměním na pedagogické fakultě Univerzity J. E. Purkyně v Ústí nad Labem a přednáší na katedře primárního vzdělávání pedagogické fakulty Technické univerzity v Liberci. V současné době je i kurátorem nově vzniklé Galerie Zahradní dům v Teplicích.',
    'Patří k výtvarným umělcům se širokými zájmy. Jeho výtvarný princip je schopen do sebe vstřebat podněty časově a stylově od sebe značně vzdálené. Známa jsou jeho květinová zátiší, městské veduty, litografická ex libris. V tvorbě z posledního období, kdy se zaměřuje zejména na krajinu ženského těla, se projevuje jeho sklon k hořké lyričnosti a dramatičnosti s lehce erotickým, ale i sarkastickým akcentem. Stálá je však přítomnost racionálních prvků i v těch nejemocionálnějších stavech, zážitcích, fantazii… Více se orientuje do svého nitra a jeho tvorba se posunuje od reality přes inspirativní zážitek a pocit do výtvarného řádu s nedílnou mírou stylizace. Kontrastem tónů a polotónů zvýrazňuje úlohu světla. Vystihuje výraz prostřednictvím čar, ploch, světla, stínů, otisků, tisků, frotáží… Je to výpověď člověka o světě, ve kterém žije a který ho obklopuje. Uspořádal přes 90 samostatných výstav a zúčastnil se více jak 290 kolektivních přehlídek, je zastoupen ve veřejných a soukromých sbírkách doma a ve světě. Zúčastnil se i řady mezinárodních výtvarných sympozií.',
  ],
  'ilja-savel': [
    'Akademický malíř – malíř a grafik',
    'Narozen 9. února 1951 v Ervěnicích',
    'Zemřel 25. prosince 2020 v Teplicích',
    'Vystudoval obor monumentální malby v architektuře na Střední uměleckoprůmyslové škole v Uherském Hradišti. Dále studoval na Akademii výtvarných umění v Praze v přípravce u doc. Oldřicha Oplta a v malířském ateliéru prof. Františka Jiroudka.',
    'Malířský projev Ilji Šavla byl spontánní, dramatický, s přesně vyváženou funkcí barvy, malířského rukopisu, názoru na prostor, osvětlení a vůbec celkové uspořádání obrazu. Kompozice jeho děl je sevřená, vyvážená, založená na přesně vymezených prostorových vztazích a charakteru proporcí. Jeho doménou byla krajina a zátiší. Po dokončení studií byla pro jeho tvorbu severočeských důlních motivů příznačná potemnělá hnědavá barevnost, jakoby revolta proti barevné prozářenosti krajin jeho profesora na akademii. V pozdější době nastupuje pro něj na dlouhá léta zcela příznačná barevná skladba, ono až agresivní vybělení a prosvětlení plochy a zvláštní pocit, spočívající v tom, že předměty jsou sice vystavěny ve svých objemech a hmotách v architektonické skladebnosti, ale ve svých vzájemných vztazích i ve vztahu ke své základně a vůbec k veškerému okolí jsou labilní, jako by se vznášely prostorem. Předměty jsou éterické, až rozpuštěné ve světle, vznášejí se. Tento imaginativní prvek, který nechává tušit hlubší zdroje, je typický i pro jeho grafickou tvorbu, kterou celou prostupují motivy vesnic, vedut a květin. Inspirativním zdrojem se pro něj stala i tvorba manželů Linky a Antonína Procházkových, svým zájmem o pastóznost a plasticitu malby a až antikizující fenomén.',
    'Velkým objevem pro něj bylo sběratelství starých dobových pohlednic s motivy Teplic a míst zmizelých z mapy světa díky dolování. Objevil zde i staré svatební fotografie, které volně přetvořil v monumentální plátna, se kterými slavil úspěchy v západní Evropě. Dokladem zájmu o jeho tvorbu je i zlatá medaile z Veletrhu umění v Paříži.',
    'Pod vlivem nepřízně osudu a dramatických rodinných událostí se změnila i jeho tvorba, do níž se pohroužil, a která jej zcela pohltila a dala zčásti zapomenout. Kompozičně se zpevnil, lineární výstavba se zaostřila a od až agresivní vybělenosti se jeho plátna dramaticky probarvila. Nesou i jisté znaky dekorativního opakování a tvůrčího schematismu, to vše jakoby pod vlivem znovuobjevení art déca. V posledních několika dílech se objevuje již stará známá hnědavá a okrová barevnost až nostalgicky starožitná. Jsou zde jisté znaky zklidnění, vyrovnání se životem a jeho nástrahám.',
  ],
};

const strings = getLocaleStrings();

const submenu = [
  { name: strings['about_author'], url: '/about' },
  { name: strings['creation'], url: '/arts' },
];

export const authors = [
  {
    id: 'vladimir-savel',
    name: 'Vladimír Šavel',
    desc: strings['vladimir-savel_title'],
    urlImage: vladimirSavel,
    submenu,
  },
  {
    id: 'vladimir-savel-jr',
    name: 'Vladimír Šavel jr.',
    desc: strings['vladimir-savel-jr_title'],
    urlImage: vladimirSavelJr,
    submenu,
  },
  {
    id: 'ilja-savel',
    name: 'Ilja Šavel',
    desc: strings['ilja-savel_title'],
    urlImage: iljaSavel,
    submenu,
  },
];
