import React from 'react';
import { getLocaleStrings } from '../functions';

import ContactForm from './ContactForm';

import Modal from 'react-bootstrap/modal';
import Alert from 'react-bootstrap/Alert';

const ContactModal = ({ isOpen, close, preFilledText, callback }) => {
  const strings = getLocaleStrings();
  return (
    <Modal show={isOpen} onHide={close} centered>
      <Modal.Header closeButton>
        <Modal.Title>{strings['form-for-buy-art']}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant={'info'}>
          <strong>{strings['after-send-info']}</strong>
        </Alert>
        <ContactForm
          id={'contactForm'}
          preFilledText={preFilledText}
          callback={callback}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ContactModal;
