import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loader = ({ wrapped }) => {
  if (wrapped)
    return (
      <div className="d-flex flex-fill justify-content-center align-items-center">
        <Spinner animation="border" role="status" variant="secondary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );

  return (
    <Spinner animation="border" role="status" variant="secondary">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
};

export default Loader;
