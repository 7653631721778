import axios from 'axios';
import config from './config.json';
import strings from './assets/strings';

const formatter = new Intl.NumberFormat('cs', {
  style: 'currency',
  currency: 'CZK',
  currencyDisplay: 'symbol',
  maximumFractionDigits: 0,
});

export function updateTitle(title) {
  if (title) return (document.title = `${config.pageName} - ${title}`);

  return (document.title = `${config.pageName}`);
}

export function formatCurrency(price) {
  return formatter.format(price);
}

/**
 * Funkce pro získání postů ze systému WordPress
 */
export function asyncFetchPosts(postType, params) {
  let query = '';

  if (params) {
    query +=
      '?' +
      Object.keys(params)
        .map((key) =>
          params[key] !== 'none' ? key + '=' + params[key] : false,
        )
        .filter(Boolean)
        .join('&');
  }

  return new Promise((resolve, reject) => {
    axios
      .get(`${config.host}/wp-json/wp/v2/${postType}${query}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log('error: ', err);
        resolve(err);
      });
  });
}

export async function sendEmail(formData) {
  console.log('sendEmail', formData);
  return new Promise((resolve, reject) => {
    axios
      .post(`${config.host}/wp-json/contact/v1/send`, formData)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log('error: ', err);
        resolve(err);
      });
  });
}

export function getLocaleStrings() {
  const browserLang = window.navigator.language;
  let defaultLang = 'cs';

  if (['cs', 'en', 'de'].includes(browserLang)) {
    defaultLang = browserLang;
  }

  const locale = localStorage.getItem('locale') || defaultLang;
  const localeStrings = strings?.[locale] || strings?.cs;

  return localeStrings;
}
