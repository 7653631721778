import React from 'react';
import { getLocaleStrings } from '../functions';

import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

const getFilters = ({ creators = [], techniques = [] }, mode) => {
  const strings = getLocaleStrings();
  const filters = [
    {
      id: 'technique',
      label: strings['technique'],
      options: [
        { value: 'none', label: strings['all'] },
        ...techniques?.map((t) => ({
          value: t?.id,
          label: t?.title?.rendered,
        })),
      ],
    },
    {
      id: 'availability',
      label: strings['availability'],
      options: [
        { value: 'none', label: strings['all'] },
        { value: '1', label: strings['available'] },
        { value: '0', label: strings['unavailable'] },
      ],
    },
    {
      id: 'order_by',
      label: strings['sorting'],
      options: [
        { value: 'none', label: strings['default'] },
        { value: 'year,asc', label: strings['sort-oldest'] },
        { value: 'year,desc', label: strings['sort-newest'] },
      ],
    },
  ];

  /*   if (mode === 'all')
    filters.unshift({
      id: 'creator',
      label: strings["author"],
      options: [
        { value: 'none', label: 'Všichni' },
        ...creators?.map((c) => ({
          value: c?.id,
          label: c?.title?.rendered,
        })),
      ],
    }); */

  return filters;
};

const FilterItem = ({ id, label, options, defaultValue }) => {
  return (
    <Col xs={'auto'}>
      <FloatingLabel controlId={id} label={label}>
        <Form.Select
          value={defaultValue || ''}
          aria-label={label}
          onChange={() => {}}>
          {options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </Col>
  );
};

const Filters = ({ mode, open, dials, onChange, filter }) => {
  const _onChange = (data) => {
    const { value, id } = data?.target || {};
    onChange(id, value);
  };

  const reset = () => {
    onChange('reset');
  };

  const filters = getFilters(dials, mode);

  return (
    <Collapse in={!!open}>
      <Form onChange={_onChange}>
        <Row className="justify-content-end align-items-center">
          {filters.map((i) => (
            <FilterItem
              key={i.id}
              {...i}
              defaultValue={filter?.[i.id] || 'none'}
            />
          ))}
          <Col xs={'auto'}>
            <Button onClick={reset}>Reset</Button>
          </Col>
        </Row>
      </Form>
    </Collapse>
  );
};

export default Filters;
