import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ReactGA } from '../App';
import {
  updateTitle,
  formatCurrency,
  asyncFetchPosts,
  getLocaleStrings,
} from '../functions';

import Loader from '../components/Loader';
import ContactModal from '../components/ContactModal';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';

const DetailItem = ({ name, value }) => (
  <>
    <dt className="col-12">{name}</dt>
    <dd className="col-12">{value}</dd>
  </>
);

class ArtPage extends Component {
  state = {
    isLoaded: false,
    art: null,
  };

  strings = getLocaleStrings();

  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleFormResponse = this.handleFormResponse.bind(this);
    this.changeArt = this.changeArt.bind(this);
    this.backToArts = this.backToArts.bind(this);
    this.nextArt = this.nextArt.bind(this);
    this.prevArt = this.prevArt.bind(this);
    this.updateArt = this.updateArt.bind(this);
    this.showFullImage = this.showFullImage.bind(this);
  }

  componentDidMount() {
    const { pathname } = window.location;

    ReactGA.send({ hitType: 'pageview', page: pathname });

    this.checkData(() => {
      this.updateArt();
    });

    this.backListener = this.props.history.listen((location) => {
      if (this.props.history.action === 'POP') {
        this.updateArt();
      }
    });
  }

  checkData(callback) {
    if (this.props.dials.creators?.length > 0) return callback();
    else
      setTimeout(() => {
        this.checkData(callback);
      }, 300);
  }

  static getDerivedStateFromProps(props, state) {
    const title = state?.art?.name;
    if (title) updateTitle(title);
    return null;
  }

  /**
   * Fetch art by slug
   *
   * @param {string} slug
   */
  async fetchArt(slug) {
    this.setState({ isLoaded: false });

    const params = {
      single_art: 1,
      slug,
      ...this.state.filter,
      ...(this.props?.location?.state?.filter || {}),
    };

    const post = await asyncFetchPosts('arts', params);

    if (Array.isArray(post.data) && post.data.length > 0) {
      this.setState({
        art: this.props.formatArts(post.data)[0],
        next: post?.data?.[0]?.next?.slug,
        prev: post.data?.[0]?.previous?.slug,
        isLoaded: true,
        slug,
        error: null,
      });
    } else {
      this.setState({
        isLoaded: true,
        error: this.strings['art-not-found'],
      });
    }
  }

  /**
   * Open modal and set pre-filed text
   *
   * @param {*} preFilledText
   */
  openModal(preFilledText) {
    this.setState({ modalOpen: true, preFilledText });
  }

  /**
   * Close modal and reset pre-filed text
   */
  closeModal() {
    this.setState({ modalOpen: false, preFilledText: undefined });
  }

  /**
   * Handle response from sended email
   *
   * @param {'success'|'failed'} resultType result type
   * @param {object} alert Alert object - { variant, content }
   */
  handleFormResponse(resultType, alert) {
    this.setState({ alert });
    this.closeModal();
  }

  updateArt() {
    // Vezme data z URL
    const { slug } = this.props.match.params;

    // Aktuální url slug souhlasí se stavem
    if (slug === this.state.slug) return;

    this.fetchArt(slug);
  }

  changeArt(slug) {
    const { path } = this.props.match || {};
    const url = path.replace(':slug', slug);

    this.props.history.push({
      pathname: url,
      state: this.props.location.state,
    });

    this.fetchArt(slug);
  }

  backToArts() {
    this.props.history.push(this.props?.location?.state?.backToArts || '/arts');
  }

  nextArt() {
    this.changeArt(this.state.next);
  }

  prevArt() {
    this.changeArt(this.state.prev);
  }

  showFullImage(show) {
    this.setState({ showFullImage: show });
  }

  render() {
    //if (!this.state.isLoaded) return <Loader wrapped />;
    if (this.state.error)
      return (
        <div className="d-flex flex-fill justify-content-center align-items-center">
          <h3 className="text-danger">
            <strong>{this.state.error}</strong>
          </h3>
        </div>
      );

    const {
      imageL,
      name,
      creator,
      technique,
      year,
      size,
      price,
      availability,
    } = this.state?.art || {};

    const strings = this.strings;

    const { alert } = this.state;

    const details = [
      { name: strings['author'], value: creator?.title?.rendered },
      { name: strings['technique'], value: technique?.title?.rendered },
      { name: strings['size'], value: size },
      { name: strings['year'], value: year },
    ];

    const detailsList = details.map((i) => <DetailItem key={i.name} {...i} />);

    const preparePlaceholder = (val) => {
      return val
        .replace('{{name}}', name)
        .replace('{{creator}}', creator?.title?.rendered);
    };

    const askPriceText = preparePlaceholder(strings['pre-fill-form_ask-price']);
    const buyText = preparePlaceholder(strings['pre-fill-form_buy-text']);

    return (
      <>
        <Container className="my-4 d-flex flex-fill justify-content-space-around align-items-center flex-column position-relative user-select-none">
          {!this.state.isLoaded && <Loader wrapped />}
          {alert && (
            <Alert variant={alert?.variant} className={`border-0 text-center`}>
              <strong> {alert?.content} </strong>
            </Alert>
          )}
          {this.state.isLoaded && (
            <>
              <ControlTopBar
                backToArts={this.backToArts}
                nextArt={this.nextArt}
                prevArt={this.prevArt}
                next={this.state.next}
                prev={this.state.prev}
              />
              <Row>
                <Col>
                  <Image
                    className="art-page-image"
                    src={imageL}
                    onClick={() => this.showFullImage(true)}
                  />
                </Col>
                <Col className="d-flex flex-column">
                  <h2>{name}</h2>
                  <dl className="row">{detailsList}</dl>
                  <div className="mt-auto">
                    {availability ? (
                      <p className="text-success">
                        <strong>{strings['available']}</strong>
                      </p>
                    ) : (
                      <p className="text-danger">
                        <strong>{strings['unavailable']}</strong>
                      </p>
                    )}
                    <p>
                      <big>
                        {price
                          ? formatCurrency(price)
                          : availability && (
                              <Button
                                onClick={() => this.openModal(askPriceText)}>
                                {strings['ask-price']}
                              </Button>
                            )}
                      </big>
                    </p>
                    <div>
                      {price && availability && (
                        <Button onClick={() => this.openModal(buyText)}>
                          {strings['buy']}
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
          <ContactModal
            isOpen={this.state.modalOpen}
            close={this.closeModal}
            preFilledText={this.state.preFilledText}
            callback={this.handleFormResponse}
          />
          <FullscreenImage
            show={this.state.showFullImage}
            setShow={this.showFullImage}
            nextArt={this.nextArt}
            prevArt={this.prevArt}
            next={this.state.next}
            prev={this.state.prev}
            src={imageL}
          />
        </Container>
      </>
    );
  }
}

const ControlTopBar = ({ backToArts, nextArt, prevArt, next, prev }) => {
  const strings = getLocaleStrings();

  return (
    <Row className="mb-2 p-2 w-100 justify-content-between user-select-none">
      <Col xs={6} md={'auto'}>
        <Button onClick={backToArts}>{strings['back']}</Button>
      </Col>
      <Col xs={6} md={'auto'}>
        <Row>
          <ButtonGroup className="mb-2">
            <Button
              disabled={!prev}
              onClick={prevArt}
              className="arrow-button user-select-none"
              variant="light">
              &lt;
            </Button>
            <Button
              disabled={!next}
              onClick={nextArt}
              className="arrow-button user-select-none"
              variant="light">
              &gt;
            </Button>
          </ButtonGroup>
        </Row>
      </Col>
    </Row>
  );
};

const FullscreenImage = ({
  setShow,
  show,
  src,
  prevArt,
  nextArt,
  prev,
  next,
}) => {
  return (
    <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
      <Modal.Body className="bg-light d-flex justify-content-center align-items-center">
        <div className="position-absolute top-0 end-0 p-3 m-2 d-flex align-items-center">
          <Button
            disabled={!prev}
            onClick={prevArt}
            className="arrow-button user-select-none"
            variant="light">
            &lt;
          </Button>
          <Button
            disabled={!next}
            onClick={nextArt}
            className="arrow-button user-select-none"
            variant="light">
            &gt;
          </Button>
          <CloseButton
            aria-label="Hide"
            onClick={() => setShow(false)}
            className="p-3 m-2"
          />
        </div>
        <Image className="mh-100" style={{ maxWidth: '90vw' }} src={src} />
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(ArtPage);
