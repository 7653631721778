import React, { useState, useCallback } from 'react';
import { getLocaleStrings } from '../functions';

import ContactForm from '../components/ContactForm';

import { Container, Row, Col, Alert } from 'react-bootstrap';

const ContactPage = () => {
  const strings = getLocaleStrings();
  const [alert, setAlert] = useState();

  const handleResponse = useCallback((resultType, alert) => {
    if (alert) setAlert(alert);
  }, []);

  return (
    <Container className="d-flex flex-1 flex-column flex-fill my-4">
      <Row className="align-items-center">
        <Col>
          <h2>{strings['contact']}</h2>
        </Col>
        <Col className="d-flex justify-content-end"></Col>
      </Row>
      <Row className="align-items-center mt-4">
        <Col sm="4">
          <h4>{strings['contact-form']}</h4>
          {alert && <Alert variant={alert?.variant}>{alert.content}</Alert>}
          <ContactForm callback={handleResponse} />
        </Col>
      </Row>
    </Container>
  );
};

export default ContactPage;
