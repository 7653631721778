import React from 'react';
import { Link } from 'react-router-dom';
import { getLocaleStrings } from '../functions';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LangSwitch from './LangSwitch';

const Footer = () => {
  const strings = getLocaleStrings();
  return (
    <footer className="footer mt-auto py-3 bg-dark text-white">
      <Container>
        <Row>
          <Col>© {strings['copyright']}. Savel.cz</Col>
          <Col
            className="d-flex justify-content-end align-items-center"
            style={{ textAlign: 'right' }}>
            <LangSwitch />
            <Link to="/gdpr" className="link">
              {strings['gdpr']}
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
