import React, { useMemo, useEffect, useState } from 'react';
import { matchPath, useHistory } from 'react-router-dom';

import AuthorCard from './AuthorCard';

import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const Authors = ({ authors }) => {
  const match = matchPath(window.location.pathname, {
    path: '/author/:id/:page',
  });
  const { page, id: activeId } = match?.params || {};

  const [activeKey, setActiveKey] = useState(page);
  const activeCreator = activeId && authors.find((a) => a.id === activeId);
  const { name, submenu, id } = activeCreator || {};

  const history = useHistory();

  useEffect(() => {
    if (window.innerWidth < 600) {
      setTimeout(() => {
        document.getElementById('authorHeading').scrollIntoView(true);
      }, 300);
    }
  }, [activeId]);

  useEffect(() => {
    setActiveKey('/' + page);
  }, [page]);

  const onSelect = (url) => {
    setActiveKey(url);
    history.push(`/author/${id}` + url);
  };

  const list = useMemo(
    () =>
      authors.map((a) => (
        <AuthorCard
          key={a.id}
          isActive={activeId === a.id}
          id={a.id}
          name={a.name}
          desc={a.desc}
          urlImage={a.urlImage}
        />
      )),
    [activeId, authors],
  );

  return (
    <Container
      className={`px-4 d-flex flex-fill flex-column justify-content-center author-cards`}>
      <Row xs={3} lg={5} className="justify-content-center py-5">
        {list}
      </Row>
      <h2
        id={'authorHeading'}
        className="text-center text-white pt-5 authorName">
        {name}
      </h2>
      {submenu && (
        <Nav className="py-2" activeKey={activeKey} onSelect={onSelect}>
          {submenu.map((i) => (
            <Nav.Item key={i.url}>
              <Nav.Link eventKey={i.url}>{i.name}</Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      )}
    </Container>
  );
};

export default Authors;
